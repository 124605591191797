import logo from "@/assets/dddiLogo.jpg";
import InSvg from "@/assets/svg/InSvg";
import Icon from "@ant-design/icons";

const Footer = () => {
  return (
    <>
      <footer className={`w-full text-white bg-[--color-footer-bg]`}>
        <div className="page-content a-hover-underline-list">
          {/* Main footer content with sections */}
          <div className="lg:flex justify-between border-t-2 border-gray-500 py-10">
            {/* Left section: Traditional Acknowledgement and Logo */}
            <div className="lg:w-2/5 flex flex-col gap-5 mr-20">
              {/* Increased margin-right for spacing */}

              {/* Logo with Text Below */}
              <div className="flex flex-col items-start">
                <a href="/" className="w-full">
                  <img src={logo} alt="DDDI Logo" className="w-40 h-20" />{" "}
                  {/* Logo size adjusted */}
                </a>
                {/* "Data Driven Development Intelligence" text */}
                <div className="mt-4 text-left text-lg font-bold">
                  <p>
                    <span className="text-red-600">D</span>
                    <span className="text-white">ata</span>
                  </p>
                  <p>
                    <span className="text-red-600">D</span>
                    <span className="text-white">riven</span>
                  </p>
                  <p>
                    <span className="text-red-600">D</span>
                    <span className="text-white">evelopment</span>
                  </p>
                  <p>
                    <span className="text-red-600">I</span>
                    <span className="text-white">ntelligence</span>
                  </p>
                </div>
              </div>
              {/* Traditional Acknowledgement */}
              <div className="text-sm" style={{fontSize:10, paddingTop:40}}>
                <p>
                  DDDI Group recognises the Traditional Owners and Custodians of
                  lands across Australia. We extend our respects to Elders past
                  and present, and honour the ongoing cultural, spiritual, and
                  educational practices of Aboriginal and Torres Strait Islander
                  peoples.
                </p>
              </div>
            </div>

            {/* Right section: Navigation Links */}
            <div className="lg:w-3/5 flex flex-col text-sm mt-10 lg:mt-0">
              {/* Top section: Home, What we do, Proud group members */}
              <div className="flex gap-20">
                {/* Increased gap */}
                {/* "Home" section */}
                {/* <ul className="flex flex-col space-y-2">
                  <li className="font-bold text-xl">Home</li>{" "}
                  <li>
                    <a href="/company">The company</a>
                  </li>
                  <li>
                    <a href="/mission">Our mission</a>
                  </li>
                  <li>
                    <a href="/glance">At a glance</a>
                  </li>
                  <li>
                    <a href="/news">In the news</a>
                  </li>
                </ul> */}
                {/* "What we do" section */}
                {/* <ul className="flex flex-col space-y-2 ml-14">
                  {/* Increased margin-left for right shift *
                  <li className="font-bold text-xl">What we do</li>{" "}
                  
                  <li>
                    <a href="/projects">Projects</a>
                  </li>
                </ul> */}
                {/* "Proud group members" section */}
                <ul className="flex flex-col space-y-2 ml-12">
                  {/* Increased margin-left for right shift */}
                  <li className="font-bold text-xl">
                    Proud Group Members
                  </li>{" "}
                  {/* Font size increased */}
                  <li>
                    <a href="https://www.cyberate.com.au">Cyberate Technologies</a>
                  </li>
                  <li>
                    <a href="https://www.cyberate.investments">Cyberate Investment</a>
                  </li>
                  <li>
                    <a href="https://www.cyberate.finance">Cyberate Finance</a>
                  </li>
                  <li>
                    <a href="https://www.cyberate.com.au">Cyberate Project Management</a>
                  </li>
                  <li>
                    <a href="https://www.cyberidge.com.au">Cyberidge Construction</a>
                  </li>
                  <li>
                    <a href="https://www.quantdigital.au">Quant Digital</a>
                  </li>
                </ul>
              </div>

              {/* Bottom section: News, Careers, About us */}
              <div className="flex gap-20 mt-10">
                {/* Increased gap */}
                {/* "News" section */}
                {/* <ul className="flex flex-col space-y-2">
                  <li className="font-bold text-xl">News</li>{" "}
                  <li>
                    <a href="/federal-government">With State Government</a>
                  </li>
                  <li>
                    <a href="/city-council">With city council</a>
                  </li>
                  <li>
                    <a href="/others">Others</a>
                  </li>
                </ul> */}
                {/* "Careers" section moved slightly to the left */}
                <ul className="flex flex-col space-y-2 -ml-4">
                  {/* Negative margin-left for slight left shift */}
                  {/* <li className="font-bold text-xl">Careers</li>{" "}
                  <li>
                    <a href="/brand-story">Careers</a>
                  </li> */}
                  {/* Font size increased */}
                </ul>
                {/* "About us" section moved slightly to the left */}
                {/* <ul className="flex flex-col space-y-2 ml-12">
                  <li className="font-bold text-xl">About us</li>{" "}
                  <li>
                    <a href="/brand-story">Brand story</a>
                  </li>
                  <li>
                    <a href="/office">Office</a>
                  </li>
                  <li>
                    <a href="/team">Our team</a>
                  </li>
                  <li>
                    <a href="/partners">Our Partners</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact us</a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>

          {/* Bottom section: Links and Copyright */}
          <div className="py-10 text-sm flex flex-col lg:flex-row justify-between items-center">
            {/* Social media icon */}
            <ul className="flex gap-x-5">
              <li>
                <a href="/">
                  <Icon
                    component={InSvg}
                    style={{ width: "1.8rem", height: "1.8rem", color: "#fff" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00AEEF";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#fff";
                    }}
                  />
                </a>
              </li>
            </ul>
          </div>

          {/* Links section for Privacy Policy, Conditions of Use, Cookies, Sitemap */}
          {/* <div className="py-5 text-sm text-center">
            <a href="/privacy-policy" className="hover:underline mx-2">
              Privacy Policy
            </a>
            <span className="mx-2">｜</span>
            <a href="/conditions-of-use" className="hover:underline mx-2">
              Conditions of Use
            </a>
            <span className="mx-2">｜</span>
            <a href="/cookies" className="hover:underline mx-2">
              Cookies
            </a>
            <span className="mx-2">｜</span>
            <a href="/sitemap" className="hover:underline mx-2">
              Sitemap
            </a>
          </div> */}

          {/* Copyright notice */}
          <p className="text-xs pb-8 text-center lg:text-left">
            Copyright © 2024 DDDI Group. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
