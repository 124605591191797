import { PhoneFilled, MobileFilled, MailFilled } from "@ant-design/icons";
import { Popover } from "antd";

const SearchResultsItem = (props) => {
  const { img, subTitle, title, description, link, phone, mobile, email } =
    props;

  return (
    <>
      <div className="flex md:flex-row flex-col border-t pt-8 pb-2">
        <div className="lg:w-96 md:w-96">
          <a href={link}>
            <img src={img} alt={title} className="w-full" />
          </a>
        </div>
        <div className="md:ml-6 lg:ml-10">
          <p className="text-sm mt-5">{subTitle}</p>
          <h1 className="text-xl my-2">
            <a
              href={link}
              className="hover:underline underline-offset-2 decoration-2"
            >
              {title}
            </a>
          </h1>
          <p className="text-base">{description}</p>

          <ul className="flex flex-wrap gap-4 mt-8">
            {phone && (
              <li>
                <Popover placement="top" content={phone}>
                  <a
                    href={"tel:" + phone}
                    className={`hover:text-[--active-color]`}
                  >
                    <PhoneFilled className="mr-2 transition-colors duration-200 ease-in-out" />
                    <span className="text-sm text-gray-500 hover:underline underline-offset-1 decoration-2">
                      Phone
                    </span>
                  </a>
                </Popover>
              </li>
            )}

            {mobile && (
              <li>
                <Popover placement="top" content={mobile}>
                  <a
                    href={"tel:" + mobile}
                    className={`hover:text-[--active-color]`}
                  >
                    <MobileFilled className="mr-2 transition-colors duration-200 ease-in-out" />
                    <span className="text-sm text-gray-500 hover:underline underline-offset-1 decoration-2">
                      Mobile
                    </span>
                  </a>
                </Popover>
              </li>
            )}
            {email && (
              <li>
                <a
                  href={"mailto:" + email}
                  className={`hover:text-[--active-color]`}
                >
                  <MailFilled className="mr-2 transition-colors duration-200 ease-in-out" />
                  <span className="text-sm text-gray-500 hover:underline underline-offset-1 decoration-2">
                    Email
                  </span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default SearchResultsItem;
