import React from "react";
// import HeaderOne from "./header/HeaderOne";
// import HeaderTopNews from "./header/HeaderTopNews";
// import FooterTwo from "./footer/FooterTwo";
// import Copyright from "./footer/Copyright";

import NavBar from "./header/navBar/NavBar";
import Footer from "./footer/Footer";

const Layout = ({ children }) => {
  return (
    <>
      <NavBar />
      <main>
        {/* <HeaderTopNews /> */}
        {/* <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" /> */}
        {children}
        {/* <FooterTwo /> */}
        {/* <Copyright /> */}
      </main>
      <Footer></Footer>
    </>
  );
};
export default Layout;
