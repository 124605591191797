import React, { useState, useEffect } from "react";

import { DownOutlined } from "@ant-design/icons";

import styles from "./SearchHeadDropdown.module.scss";

const SortBy = (props) => {
  const { optionsList, onChangeSory } = props;

  const [isItemSelected, setIsItemSelected] = useState(false);

  const [checkItem, setCheckItem] = useState(optionsList[0]);
  const handleDocumentClick = (event) => {
    const targetDiv = document.querySelector(".sort-by-div");

    if (!targetDiv || (targetDiv && !targetDiv.contains(event.target))) {
      setIsItemSelected(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const changeItem = (item) => {
    setCheckItem(item);
    onChangeSory(item);
  };

  return (
    <>
      <div
        className={`${styles["SearchHeadDropdown"]} sort-by-div ${
          isItemSelected ? styles["SearchHeadDropdown_active"] : ""
        } `}
      >
        <div
          className="cursor-pointer text-sm lg:hover:bg-slate-100 px-4 py-4 lg:py-2 transition-colors duration-300 ease-in-out"
          onClick={() => setIsItemSelected(!isItemSelected)}
        >
          <div
            className="lg:border-b-2 lg:border-sky-400 py-2 lg:text-gray-950 text-white
          flex items-center justify-between lg:inline-block
          "
          >
            {checkItem && <span>{checkItem.label}</span>}
            <DownOutlined
              className={`transition-all duration-300 ease-in-out ml-4 ${
                isItemSelected ? "rotate-180" : ""
              }`}
            />
          </div>
        </div>

        <ul className="lg:w-60 right-0 lg:bg-slate-300">
          {(optionsList || []).map((item, index) => (
            <li
              key={index}
              onClick={() => changeItem(item)}
              className={`flex items-center justify-between py-5 px-2 mx-2 cursor-pointer lg:text-slate-800 text-white border-t lg:border-slate-400 border-white ${
                checkItem && checkItem.id === item.id
                  ? styles["check-item"]
                  : ""
              }
            `}
            >
              <label className="flex items-center text-xs">
                <p className="">{item.label}</p>
              </label>

              <div
                className={`size-4 border lg:border-black border-white rounded-full before:rounded-full ${styles["check-icon"]} ${styles["check-icon-dark"]}`}
              ></div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SortBy;
