import { useState, useRef, useEffect } from "react";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import styles from "./HeadCarousel.module.scss";

const HeadCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isScrollEnd, setIsScrollEnd] = useState(false);

  // 图片及公司名和链接的数组
  const companies = [
    {
      name: "Cyberate Technologies",
      img: "/images/brand/Cyberate.jpg",
      link: "https://www.cyberate.com.au/",
      description: "Real Estate Big-Data Analysis",
    },
    {
      name: "Cyberate Investment",
      img: "/images/brand/Investment.jpg",
      link: "https://www.cyberate.investments/",
      description: "Property Investment Consultation",
    },
    {
      name: "Cyberate Finance",
      img: "/images/brand/Finance.jpg",
      link: "https://cyberate.finance/",
      description: "Home/Commercial Loan Services",
    },
    {
      name: "Cyberate Project Management",
      img: "/images/brand/Project management.jpg",
      link: "https://www.cyberate.com.au/",
      description: "Property Development Project Management",
    },
    {
      name: "Cyberidge Construction",
      img: "/images/brand/Cyberidge.jpg",
      link: "https://www.cyberidge.com.au/",
      description: "Civil Engineering Services",
    },
    {
      name: "Quant Digital",
      img: "/images/brand/Quant Digital.jpg",
      link: "https://quantdigital.au/",
      description: "Digital Solutions and Innovations",
    },
  ];

  const dataListRef = useRef(null);

  const navClickChange = (index) => {
    if (
      index >= -1 &&
      index <= companies.length - 1 &&
      index !== currentIndex
    ) {
      setCurrentIndex(index);
    }
  };

  const handleChangeScroll = () => {
    const gallery = dataListRef.current;
    const itemW = window.innerWidth < 1024 ? window.innerWidth : 400;

    if (gallery && !isScrolling) {
      let left = currentIndex * itemW;
      if (window.innerWidth < 1024 && currentIndex < 1) {
        left = itemW * -1;
      }

      setIsScrollEnd(left + gallery.clientWidth >= gallery.scrollWidth);

      gallery.scrollTo({
        left: left,
        behavior: "smooth",
      });
    }
  };

  useEffect(handleChangeScroll, [currentIndex, isScrolling]);

  const handleScroll = () => {
    if (window.innerWidth < 1024) {
      const gallery = dataListRef.current;
      if (gallery) {
        setIsScrolling(true);

        let newIndex = Math.round(gallery.scrollLeft / window.innerWidth);
        if (newIndex === 0) {
          newIndex = -1;
        }
        setCurrentIndex(newIndex);

        clearTimeout(gallery.scrollTimeout);
        gallery.scrollTimeout = setTimeout(() => {
          setIsScrolling(false);
        }, 100);
      }
    }
  };

  return (
    <>
      <div className="bg-[#333d4d] relative ">
        <ul
          className={`lg:hidden flex h-4 absolute top-4 left-8 right-8 z-20 gap-x-4`}
        >
          {Array.from({ length: companies.length }).map((_, index) => (
            <li
              key={"dots" + index}
              className={`px-2 h-1 relative z-30 flex-1 ${
                (currentIndex <= 0 ? 0 : currentIndex) === index
                  ? "bg-white"
                  : "bg-slate-500"
              }`}
              onClick={() => navClickChange(index)}
            ></li>
          ))}
        </ul>

        <div className={`${styles["btns"]}`}>
          <ArrowLeftOutlined
            className={`${styles["icon"]} ${
              currentIndex === -1 ? styles["icon-disabled"] : ""
            }`}
            onClick={() => navClickChange(currentIndex - 1)}
          />
          <ArrowRightOutlined
            className={`${styles["icon"]} ${
              isScrollEnd || currentIndex === companies.length - 1
                ? styles["icon-disabled"]
                : ""
            }`}
            onClick={() =>
              isScrollEnd ? () => {} : navClickChange(currentIndex + 1)
            }
          />
        </div>
        <ul
          ref={dataListRef}
          onScroll={handleScroll}
          className={`flex items-stretch overflow-y-hidden overflow-x-scroll pt-12 relative lg:static ${
            styles["carousel-items"]
          } ${currentIndex === -1 ? styles["ul-fold"] : styles["ul-expand"]}`}
        >
          <li
            className={`flex items-center flex-none bg-[#3d4b61] text-white h-full py-6 lg:px-8 md:px-6 px-4 absolute lg:top-0 lg:left-0 z-10 w-auto max-w-[400px] -top-12 mt-12 lg:mt-0 ml-0`} // 宽度根据文字自动调整
          >
            <div className="opacity-70">
              <h1 className="lg:text-4xl md:text-3xl text-lg lg:mb-6 md:mb-4 mb-3">
                Our Group Members
              </h1>
              <p className={`text-xs lg:text-sm`}>
                Supporting the local communities in which we build, live and
                work
              </p>
            </div>
            <p
              className={`hidden lg:block text-lg -rotate-90 text-nowrap opacity-0 absolute bottom-20 -right-2`}
            >
              Our Group Members
            </p>
          </li>

          {companies.map((company, index) => (
            <li
              className={`flex-none relative text-white lg:mt-16 lg:pb-8 py-10 lg:px-8 md:px-8 pl-10 ${styles["carousel-item"]}`}
              key={index}
            >
              <div>
                <p className="mb-3 lg:text-sm text-xs pr-10 md:pr-0">
                  {company.description}
                </p>
                <div className="flex lg:flex-col md:flex-row flex-col">
                  <a
                    href={company.link}
                    className={`lg:w-auto md:w-[55%] ${styles["cover"]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={company.img}
                      alt={company.name}
                      className="object-contain w-[230px] h-[230px]" // 修改尺寸
                    ></img>
                  </a>

                  <div className="lg:mt-5 md:mt-0 mt-5 overflow-y-hidden md:w-5/12 md:pl-10 lg:w-auto">
                    <h3 className="font-lab-antiqua lg:text-3xl md:text-2xl text-xl pr-10 md:pr-0 hover:underline underline-offset-4 decoration-2">
                      <a
                        href={company.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {company.name}
                      </a>
                    </h3>
                    <p
                      className={`mt-10 pr-10 md:pr-0 w-full overflow-hidden lg:h-0 lg:text-base lg:text-white text-gray-300 text-xs leading-5 text-ellipsis ${styles["description"]} `}
                    >
                      TCC is committed to understanding the environmental impact
                      of our business operations and is actively seeking ways to
                      measure and minimize those impacts, to ensure a
                      sustainable future.
                    </p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default HeadCarousel;
