import CornerMarkItem from "./CornerMarkItem";

const CornerMarkList = (props) => {
  const moduleAList = [
    {
      index: "01",
      title1: "3",
      title2: "Levels",
      content: "Of Government Partnerships - State, City Councils, and Departments",
      link: "/",
    },
    {
      index: "02",
      title1: "30",
      title2: "Millions",
      content: (
        <>
          Assets under Management <br /> (AUD)
        </>
      ),
      link: "/",
    },
    {
      index: "03",
      title1: "135",
      title2: "Properties",
      content: "Under Development/Construction/Management",
      link: "/",
    },
    // {
    //   index: "04",
    //   title: "Invest Efficiently",
    //   content:
    //     "Capture real estate development opportunities that meet your risk/return criteria through a single, centralized source.",
    //   link: "/",
    // },
    // {
    //   index: "05",
    //   title: "Invest Efficiently",
    //   content:
    //     "Capture real estate development opportunities that meet your risk/return criteria through a single, centralized source.",
    //   link: "/",
    // },
  ];

  const { headerText } = props;

  return (
    <>
      {headerText && (
        <h2
          className={`font-lab-antiqua lg:text-6xl text-5xl font-medium lg:mb-12 mb-8 text-blue-950`}
        >
          {headerText}
        </h2>
      )}

      <ul className={`w-full flex flex-wrap mb-14 `}>
        {moduleAList.map((item, index) => (
          <CornerMarkItem
            key={index}
            index={item.index}
            title1={item.title1}
            title2={item.title2}
            content={item.content}
            link={item.link}
          ></CornerMarkItem>
        ))}
      </ul>
    </>
  );
};

export default CornerMarkList;
