import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import ContactUsItemTwo from "@/components/contact-us-list/ContactUsItemTwo";

import { XOutlined } from "@ant-design/icons";

import Img01 from "@/assets/img/image01.jpg";
import InSvg from "@/assets/svg/InSvg";
import FacebookSvg from "@/assets/svg/FacebookSvg";
import MainlSvg from "@/assets/svg/MainlSvg";

import Icon from "@ant-design/icons";

const DetailPage = () => {
  return (
    <>
      <SEO title=""></SEO>
      <Layout>
        <Banner
          subTitle="Conshohocken, PA"
          titleNode={
            <p className="font-lab-antiqua text-3xl lg:text-4xl xl:text-5xl w-full md:w-auto mt-3">
              High Street Residential Sells Matson Mill Multifamily Community
              Outside Philadelphia
            </p>
          }
          bgColor="#D1D7E0"
          description="August 9, 2024"
          textColor="#0A1E41"
          img={Img01}
          bottomElement={
            <ul className="mt-6 flex gap-x-4 ">
              <li>
                <a href="/">
                  <Icon
                    component={FacebookSvg}
                    className="transition-colors duration-200 ease-in-out"
                    style={{ width: "20px", height: "20px" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00AEEF";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#0a1e41";
                    }}
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <XOutlined
                    className="transition-colors duration-200 ease-in-out"
                    style={{ fontSize: "18px" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00AEEF";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#0a1e41";
                    }}
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <Icon
                    component={InSvg}
                    className="transition-colors duration-200 ease-in-out"
                    style={{ width: "24px", height: "24px" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00AEEF";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#0a1e41";
                    }}
                  />
                </a>
              </li>
              <li>
                <a href="/">
                  <Icon
                    component={MainlSvg}
                    className="transition-colors duration-200 ease-in-out"
                    style={{ width: "22px", height: "22px" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00AEEF";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#0a1e41";
                    }}
                  />
                </a>
              </li>
            </ul>
          }
        ></Banner>

        <div className="page-content">
          <div className="mx-auto leading-8 md:leading-9 md:text-lg text-base content-text-max-w flex md:block flex-col">
            <div className="md:float-right md:ml-16 order-4">
              <div className="w-72 py-10 px-4 bg-[--color-secondary-light]">
                <p className="text-xs mb-2 uppercase">Media Contact</p>
                <ContactUsItemTwo></ContactUsItemTwo>
              </div>
            </div>
            <div className="text-lg leading-8">
              <p className="mb-10">
                High Street Residential (HSR), the residential subsidiary of
                Trammell Crow Company, has sold Matson Mill, a 280-unit
                apartment community that was delivered in 2022. Located at 101
                Washington Street in Conshohocken, Pennsylvania, HSR developed
                the property in partnership with MetLife Investment Management
                and sold it to an undisclosed buyer. CBRE marketed the property
                on behalf of HSR. The property is approximately 95% percent
                leased.
              </p>
              <p className="mb-10">
                “The successful stabilization and sale of this Class A
                multifamily asset is a testament to the quality and design of
                the property, in addition to its sought-after location and the
                overall demand for this type of product in the Philadelphia
                suburbs,” said Michael Adamo of HSR. “We are pleased to have had
                a successful development cycle with Matson Mill and are actively
                pursuing the next opportunity to bring world-class housing to
                the Philadelphia area.”
              </p>
              <p className="mb-10">
                Matson Mill is located along the Schuykill River and offers a
                mix of studio, one- and two-bedroom luxury units. The property
                includes a robust collection of amenities, including a
                resort-style club room with an entertainment kitchen; a game
                room; a conference/private lounge area; a state-of-the-art
                fitness center; a quiet lounge; a large package and mail room; a
                dog wash station; on-site bike storage; and an entry lobby/train
                waiting area. Residents also have access to outdoor courtyards
                that have views of the river and include a resort-style pool, an
                outdoor kitchen and outdoor seating areas with fire pits.
              </p>
              <p className="mb-10">
                Matson Mill is directly adjacent to the SEPTA Regional Rail
                station, which can transport residents to Center City
                Philadelphia in 30 minutes.
              </p>
            </div>
          </div>

          <div
            className={`mx-auto mt-16 leading-8 md:leading-9 md:text-lg text-base content-text-max-w `}
          >
            <h2 className="text-color-primary font-lab-antiqua text-3xl md:text-4xl lg:text-5xl mb-7">
              Trammell Crow Company
            </h2>
            Trammell Crow Company (TCC) is a global commercial real estate
            developer and wholly-owned subsidiary of CBRE Group, Inc.
            (NYSE:CBRE), a Fortune 500 and S&P 500 company headquartered in
            Dallas. Founded in 1948, TCC has developed or acquired nearly 2,900
            buildings valued at $75 billion and over 655 million square feet. As
            of June 30, 2024, TCC had $18.8 billion of projects in process and
            $13.1 billion in its pipeline. With more than 625 employees
            throughout the United States and Europe, the company serves users of
            and investors in office, industrial/logistics, healthcare, life
            sciences and mixed-use projects, as well as multi-family residential
            through its operating subsidiary High Street Residential. For more
            information visit{" "}
            <a
              href="https://www.TrammellCrow.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              www.TrammellCrow.com.
            </a>
          </div>

          <div
            className={`mx-auto mt-16 leading-8 md:leading-9 md:text-lg text-base content-text-max-w `}
          >
            <h2 className="text-color-primary font-lab-antiqua text-3xl md:text-4xl lg:text-5xl mb-7">
              High Street Residential
            </h2>
            High Street Residential, the residential subsidiary of Trammell Crow
            Company, specializes in the development of multifamily housing. We
            have a deep background in urban, infill mixed-use residential
            development, as well as the redevelopment or repurposing of existing
            facilities. In the last 15 years, the firm has completed over $4
            billion with a current pipeline of more than 6,500 units. For more
            information, visit{" "}
            <a
              href="https://www.highstreetresidential.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              www.highstreetresidential.com.
            </a>
          </div>
        </div>

        <div className="lg:h-28 md:h-16 h-8 w-full"></div>
      </Layout>
    </>
  );
};

export default DetailPage;
