import React from "react";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import Carousel from "@/components/carousel/Carousel";
import CornerMarkList from "@/components/corner-mark-list/CornerMarkList";
import CardImgTextX from "@/components/card/card-img-text-x/CardImgTextX";
import CardInImg from "@/components/card/card-in-img/CardInImg";
import NewsModule from "@/components/news-module/NewsModule";
import ContactUs from "@/components/contact-us/ContactUs";
import GroupMembers from "@/components/brand/groupMembers";

import { RightOutlined } from "@ant-design/icons";
import Img01 from "@/assets/img/image01.jpg";
import Img03 from "@/assets/img/img03.jpg";
import Img04 from "@/assets/img/img04.jpg";

import navLinkGroup from "@/data/header-link/linkGroup.json";

const IndexPage = () => {
  const sectorsLinkList = (
    navLinkGroup.CapabilitiesLinkGroup.filter(
      (item) => item.title === "Sectors"
    )[0].list || []
  ).filter((v) => v.name !== "Data Centers");

  return (
    <>
      <SEO title="DDDI Group"></SEO>
      <Layout>
        <Carousel></Carousel>

        <div className="w-full bg-white">
          <div className={`page-content`}>
            {/* Module A */}
            <CornerMarkList headerText="At a Glance"></CornerMarkList>

            {/* Module B */}
            <div className={`flex lg:flex-nowrap flex-wrap gap-5 mb-10`}>
              <div className={`w-full lg:max-w-md`}>
                <h3 className="font-lab-antiqua text-5xl font-bold py-8">
                  What We Do
                </h3>
                <div className={`text-md`}>
                  DDDI Group is a leading property development service group
                  that leverages big data to provide comprehensive, one-stop
                  services across technologies, finance services, and
                  construction services.
                </div>
              </div>
              <div className={`w-full`}>
                <ul className="mt-4 grid lg:grid-cols-2 md:grid-cols-2 gap-x-10">
                  {sectorsLinkList
                    .sort((a, b) => a.sort - b.sort)
                    .map((link, index) => (
                      <li
                        key={index}
                        className="flex justify-between border-b border-gray-300 hover:text-sky-400"
                      >
                        {/* <a href={link.url} className="block w-full h-full py-5"> */}
                          {link.name}
                        {/* </a> */}
                        <RightOutlined
                          style={{
                            color: "#0a1e41",
                            fontSize: "0.7rem",
                          }}
                        />
                      </li>
                    ))}
                </ul>
              </div>
            </div>

            {/* Proud Group Members Section */}
            <div
              style={{
                backgroundColor: "#ffffff", // Matches the page's white background
                padding: "60px 20px",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  marginBottom: "40px",
                  fontSize: "2.5em",
                  fontWeight: "bold",
                  color: "#0a1e41", // Text color matches your page
                }}
              >
                Proud Group Members
              </h2>
            </div>
            <GroupMembers />
            <br />
            <br />
{/* 
            <div className="my-20">
              <CardInImg
                images={[Img01, Img03, Img04]}
                subTitle="Featured Projects"
                title="Projects"
                description="DDDI Group utilises data and technology to deliver
                precise investment insights, empowering investors to
                achieve lasting wealth growth."
                linkLeft="https://cyberate.projectmanagement/"
                linkLeftText="Learn More"
                linkRight="https://cyberate.projectmanagement/"
                linkRightText="All Projects"
              />
            </div>

            <div className="mt-20">
              <NewsModule
                headText="In the News"
                bigItemTitle={false}
                rightLink="/news"
                rightLinkText="Learn More"
              ></NewsModule>
            </div> */}
          </div>

          <ContactUs></ContactUs>
        </div>
        <div className="lg:h-28 md:h-16 h-8 w-full"></div>
      </Layout>
    </>
  );
};

export default IndexPage;
