import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import BannerPro from "@/components/banner/banner-pro/BannerPro";
import ContactUsList from "@/components/contact-us-list/ContactUsList";

import Img02 from "@/assets/img/img02.png";

const DetailPage = () => {
  return (
    <>
      <SEO title="Mercer on Fillmore"></SEO>
      <Layout>
        <BannerPro
          title="Mercer on Fillmore"
          bgColor="#333D4D"
          description="Mercer on Fillmore is a seven-story, Class A+ multifamily project located at the epicenter of Downtown Phoenix."
          textColor="#ffffff"
          img={Img02}
        ></BannerPro>

        <div className="page-content">
          <div
            className={`mx-auto leading-8 md:leading-9 md:text-lg text-base content-text-max-w `}
          >
            <h2 className="text-color-primary font-lab-antiqua text-3xl md:text-4xl lg:text-5xl mb-7">
              Overview
            </h2>
            Mercer on Fillmore is a seven-story, Class A+ multifamily project
            located just off the heart Phoenix’s booming downtown. The project
            includes 348-units and 7,000 square feet of retail space, along with
            unique luxury resident amenities.
          </div>

          <div
            className={`mx-auto leading-8 md:leading-9 md:text-lg text-base content-text-max-w mt-10 `}
          >
            Mercer is a best-in-class housing option, providing the best of both
            worlds with a relatively quiet location just off the main streets of
            downtown while also easily accessible to the employment and local
            entertainment of the city center. The project sits on a 2.8-acre
            site, offering alcoves, 1-bedroom, 2-bedroom and 3-bedroom units.
            Resident amenities include a pet spa, bike storage and workshop,
            concierge lobby and beverage station, expansive fitness center
            overlooking the city skyline, yoga studio, club room, chef’s
            demonstration kitchen, a resort-style pool deck and outdoor kitchen
            and a secondary courtyard with pet lawn, sky-decks overlooking the
            city and multiple lounges, and a hidden tequila speakeasy. Mercer on
            Fillmore residents have access to on-site management, heated pool,
            private storage lockers, expansive private balconies, and views of
            the city, along with a guest suite for their visitors.
          </div>

          <div className="mt-20">
            <ContactUsList></ContactUsList>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DetailPage;
