import React, { useRef, useState, useEffect, useCallback } from "react";
import styles from "./Carousel.module.scss";

import ImageA from "@/assets/img/a.jpg";
import ImageB from "@/assets/img/b.jpg";
import ImageC from "@/assets/img/c.jpg";
import ImageD from "@/assets/img/d.png";

import { ArrowDownOutlined } from "@ant-design/icons";

const Carousel = () => {
  const carouselList = [
    {
      sort: 10,
      title: "Property Development Solutions",
      img: ImageA,
      description:
        "Employment big data in property development solutions for our investors",
      more: {
        url: "/",
        text: "Read More",
      },
    },
    {
      sort: 20,
      title: "Strategic Development Solutions",
      img: ImageB,
      description:
        "Enabling occupiers to achieve their goals with greater speed, efficiency and precision.",
      more: {
        url: "/",
        text: "Learn More",
      },
    },
    {
      sort: 30,
      title: "Our Mission",
      img: ImageC,
      description:
        "Offering investors the most precise and reliable investment insights, thereby enabling them to achieve sustained wealth growth.",
      more: {
        url: "/",
        text: "Learn More",
      },
    },
  ];

  const carouselListRef = useRef(null);
  const [currentNavIndex, setCurrentNavIndex] = useState(0);
  const [itemIn, setItemIn] = useState(false);
  const [itemOut, setItemOut] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const navClickChange = useCallback(
    (index) => {
      if (index !== currentNavIndex) {
        setItemOut(true);
        setTimeout(() => {
          setCurrentNavIndex(index);
          setItemOut(false);
          setItemIn(true);
        }, 800);

        setTimeout(() => {
          setItemIn(false);
        }, 1500);
      }
    },
    [currentNavIndex]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      let index = currentNavIndex + 1;
      if (index >= carouselList.length) {
        index = 0;
      }
      navClickChange(index);
    }, 6000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentNavIndex, carouselList.length, navClickChange]);

  useEffect(() => {
    const gallery = carouselListRef.current;
    if (gallery && !isScrolling) {
      gallery.scrollTo({
        left: currentNavIndex * window.innerWidth,
        behavior: "smooth",
      });
    }
  }, [currentNavIndex, isScrolling]);

  const handleScroll = () => {
    const gallery = carouselListRef.current;
    if (gallery) {
      setIsScrolling(true);
      const newIndex = Math.round(gallery.scrollLeft / window.innerWidth);
      setCurrentNavIndex(newIndex);

      clearTimeout(gallery.scrollTimeout);
      gallery.scrollTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 100);
    }
  };

  const carouselRef = useRef(null);
  const handleScrollToContnet = () => {
    if (carouselRef.current) {
      const targetDiv = carouselRef.current;
      const targetPosition = targetDiv.offsetTop + targetDiv.offsetHeight;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className={`w-full  ${styles["carousel"]}`} ref={carouselRef}>
        <div className={`${styles["content"]}  relative`}>
          <div
            className={`${styles["content-list"]}`}
            ref={carouselListRef}
            onScroll={handleScroll}
          >
            {carouselList.map((item, index) => (
              <div
                key={`carousel-${index}`}
                className={`${styles["content-item"]} ${
                  currentNavIndex === index ? styles["content-item-active"] : ""
                } ${itemOut ? styles["item-out"] : ""} ${
                  itemIn ? styles["item-in"] : ""
                }`}
              >
                <div className={`${styles["content-item-div"]}`}>
                  <div className={`${styles["content-title"]} text-white`}>
                    {item.title}
                  </div>
                  <div className={`${styles["content-media"]}`}>
                    <img src={item.img} alt=""></img>
                  </div>
                  <p className={`${styles["content-description"]} text-white`}>
                    {item.description}
                  </p>
                  <p>
                    <a
                      href={item.more.url}
                      className={`${styles["content-more-btn"]} text-white btn-hover`}
                    >
                      {item.more.text}
                    </a>
                  </p>
                </div>
              </div>
            ))}
          </div>

          <ArrowDownOutlined
            className={`btn-hover ${styles["arrow-btn"]}`}
            style={{
              color: "#38bdf8",
            }}
            onClick={() => handleScrollToContnet()}
            onMouseEnter={(e) => {
              e.currentTarget.style.color = "#ffffff";
              e.currentTarget.style.backgroundColor = "#38bdf8";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.color = "#38bdf8";
              e.currentTarget.style.backgroundColor = "";
            }}
          />

          <div className={`${styles["nav-ul"]} `}>
            <ul>
              <p
                className={`${styles["tag"]}  `}
                style={{
                  left: currentNavIndex * 1.75 + "rem",
                }}
              ></p>
              {carouselList.map((_, index) => (
                <li
                  key={`carousel-nav-key-${index}`}
                  onClick={() => navClickChange(index)}
                ></li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Carousel;
