import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import CarouselList from "@/components/carousel-list/CarouselList";
import CardQuote from "@/components/card/card-quote/CardQuote";
import CornerMarkList from "@/components/corner-mark-list/CornerMarkList";
import HeadCarousel from "@/components/head-carousel/HeadCarousel";
import GroupMembers from "@/components/brand/groupMembers";

import Img01 from "@/assets/img/image01.jpg";

const GroupMembersPage = () => {
  return (
    <>
      <SEO title="Group Members"></SEO>
      <Layout>
        <Banner
          title="Our Group Members"
          bgColor="#F2EDE8"
          textColor="#0A1E41"
          img={Img01}
        ></Banner>

        <div style={{ height: "40px" }}></div>

        {/* <GroupMembers /> */}

        <HeadCarousel></HeadCarousel>

        <div className="h-20"></div>
      </Layout>
    </>
  );
};

export default GroupMembersPage;
