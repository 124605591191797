import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import ContactUsItemTwo from "@/components/contact-us-list/ContactUsItemTwo";
import NewsModule from "@/components/news-module/NewsModule";
import CardImg from "@/components/card/card-img/CardImg";
import SearchHead from "@/components/search-head/SearchHead";
import ContactUsList from "@/components/contact-us-list/ContactUsList";

import Img01 from "@/assets/img/image01.jpg";

import { Pagination, ConfigProvider, Tabs } from "antd";

const Overview = () => {
  return (
    <>
      <div className="page-content">
        <div className="mx-auto leading-8 md:leading-9 md:text-lg text-base content-text-max-w flex md:block flex-col">
          <div className="md:float-right md:ml-16 order-4">
            <div className="w-72 py-10 px-4 bg-[--color-secondary-light]">
              <p className="text-xs mb-2 uppercase">Media Contact</p>
              <ContactUsItemTwo></ContactUsItemTwo>
            </div>
          </div>
          <div className="text-lg leading-8">
            <h2 className="text-color-primary font-lab-antiqua text-3xl md:text-4xl lg:text-5xl mb-7">
              Serving Greater Atlanta since 1972
            </h2>

            <p className="mb-10 font-bold">
              For more than a half century, TCC has developed Class A, marquee
              properties throughout Atlanta and the Southeast region.
            </p>

            <p className="mb-10">
              With more than eight decades of combined experience, our team
              includes experts in sourcing, underwriting and executing
              development and investment opportunities throughout the Atlanta
              region and across a variety of product types. TCC Atlanta has
              developed more than 14 million square feet of office, industrial,
              healthcare and retail properties, and has a robust pipeline of
              over 12 million square feet of projects, including developments in
              the life science and multifamily (through our High Street
              Residential subsidiary) sectors.
            </p>
            <p className="mb-10">
              Our team’s recent development projects include Twelve24,
              Innovation Business Center, Home Depot Distribution Center, Ace
              Hardware Distribution Center, Jackson 85 North Business Park, and
              the corporate headquarters and distribution facility for the
              Atlanta Community Food Bank. Currently TCC Atlanta has the first
              phase of Science Square – a mixed-use multi-phased life sciences
              district at Georgia Tech – under construction and the first phase
              of Coastal Trade Center in Savannah, Georgia is in the pipeline.
            </p>
          </div>
        </div>

        <div className="mt-32">
          <NewsModule
            headText="In the News"
            bigItemTitle={false}
            rightLink="#news"
            rightLinkText="Learn More"
          ></NewsModule>
        </div>
      </div>
    </>
  );
};

const Team = () => {
  return (
    <>
      <div className="page-content">
        <ContactUsList></ContactUsList>
      </div>
    </>
  );
};
const Projects = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const onChangePage = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  return (
    <>
      <div className="my-0 lg:-mx-8 ">
        <SearchHead></SearchHead>
      </div>

      <div className="page-content">
        <ul className="flex flex-wrap">
          {Array.from({ length: 10 }).map((item, index) => (
            <li className="lg:w-1/3 mb-8 lg:mb-0" key={index}>
              <div className="lg:pr-14 lg:pb-14">
                <CardImg
                  img={Img01}
                  title={
                    "Trammell Crow Company and CBRE IM Acquire 38,500 SQ M Logistics Site in Berlin"
                  }
                  bigTitle={true}
                  link={"/projects/trammell-crow-company"}
                  linkText="Learn More"
                ></CardImg>
              </div>
            </li>
          ))}
        </ul>

        <Pagination
          className={`Pagination`}
          align="center"
          current={currentPage}
          onChange={onChangePage}
          defaultCurrent={1}
          total={50}
        />
      </div>
    </>
  );
};

const News = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const onChangePage = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  return (
    <>
      <div className="page-content">
        <ul className="flex flex-wrap">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
            <li className="lg:w-1/3 mb-8 lg:mb-0" key={index}>
              <div className="lg:pr-14 lg:pb-14">
                <CardImg
                  img={Img01}
                  subTitle={"Berlin, Germany"}
                  title={
                    "Trammell Crow Company and CBRE IM Acquire 38,500 SQ M Logistics Site in Berlin"
                  }
                  content={"September 11, 2024"}
                  bigTitle={false}
                  link={"/newsroom/trammell-crow-company"}
                  linkText="Learn More"
                ></CardImg>
              </div>
            </li>
          ))}
        </ul>

        <Pagination
          className={`Pagination`}
          align="center"
          current={currentPage}
          onChange={onChangePage}
          defaultCurrent={1}
          total={50}
        />
      </div>
    </>
  );
};

const CareersDetailPage = () => {
  const navigate = useNavigate();
  const [tabItems] = useState([
    {
      key: "#OVERVIEW",
      label: "OVERVIEW",
      children: <Overview></Overview>,
    },
    {
      key: "#TEAM",
      label: "TEAM",
      children: <Team></Team>,
    },
    {
      key: "#PROJECTS",
      label: "PROJECTS",
      children: <Projects></Projects>,
    },
    {
      key: "#NEWS",
      label: "NEWS",
      children: <News></News>,
    },
  ]);

  const [tabsActiveKey, setTabsActiveKey] = useState("#OVERVIEW");

  const onTabChange = (item) => {
    navigate(item);
    setTabsActiveKey(item);
  };

  const hash = window.location.hash;
  useEffect(() => {
    if (
      hash &&
      tabItems.some((item) => item.key === hash.toLocaleUpperCase())
    ) {
      setTabsActiveKey(hash.toLocaleUpperCase());
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [hash, tabItems]);

  return (
    <>
      <SEO title="Atlanta"></SEO>
      <Layout>
        <Banner
          subTitle="Georgia"
          title="Atlanta"
          description="3550 Lenox Road, Suite 2200
Atlanta, GA 30326"
          bgColor="#F2EDE8"
          textColor="#0A1E41"
          img={Img01}
          bottomElement={
            <div className="mt-4 text-lg">
              <a
                href="/"
                className="underline underline-offset-4 decoration-2 decoration-sky-400 hover:decoration-slate-950"
              >
                Get Directions
              </a>
            </div>
          }
        ></Banner>

        <div className="lg:px-14 lg:py-8 md:px-8 px-6 py-4 ">
          <ConfigProvider
            theme={{
              components: {
                Tabs: {
                  inkBarColor: "#00AEEF",
                  itemSelectedColor: "#000",
                  itemHoverColor: "#000",
                },
              },
            }}
          >
            <Tabs
              activeKey={tabsActiveKey}
              defaultActiveKey={tabsActiveKey}
              items={tabItems}
              onChange={onTabChange}
            ></Tabs>
          </ConfigProvider>
        </div>
      </Layout>
    </>
  );
};

export default CareersDetailPage;
