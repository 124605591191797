import { Collapse } from "antd";
import { DownOutlined } from "@ant-design/icons";

import CollapsibleItem from "./CollapsibleItem";

const CollapsibleLabel = ({ label }) => {
  return (
    <p
      className="text-color-primary font-medium lg:text-xl text-lg hover:underline underline-offset-2  decoration-2 border-b-2 border-color-primary pb-4 "
      style={{ width: "calc(100% + 2.5rem)" }}
    >
      {label}
    </p>
  );
};

const CollapsiblePanel = (props) => {
  const { title, maxW } = props;

  const list = [
    {
      key: "1",
      label: (
        <CollapsibleLabel label="Construction Management"></CollapsibleLabel>
      ),
      children: (
        <ul className="list-outside list-disc ml-4 -mt-3">
          <CollapsibleItem text="Prepare bid package and construction contract"></CollapsibleItem>
          <CollapsibleItem text="Bid and award construction contract"></CollapsibleItem>
          <CollapsibleItem text="Manage contractor performance"></CollapsibleItem>
          <CollapsibleItem text="Site visits during construction"></CollapsibleItem>
          <CollapsibleItem text="Review and approval of construction draws"></CollapsibleItem>
          <CollapsibleItem text="Respond to issues, implement changes, coordinate as required"></CollapsibleItem>
        </ul>
      ),
    },
    {
      key: "2",
      label: <CollapsibleLabel label="Design"></CollapsibleLabel>,
      children: (
        <ul className="list-outside list-disc ml-4 -mt-3">
          <CollapsibleItem text="Prepare bid package and construction contract"></CollapsibleItem>
          <CollapsibleItem text="Bid and award construction contract"></CollapsibleItem>
          <CollapsibleItem text="Manage contractor performance"></CollapsibleItem>
          <CollapsibleItem text="Site visits during construction"></CollapsibleItem>
          <CollapsibleItem text="Review and approval of construction draws"></CollapsibleItem>
          <CollapsibleItem text="Respond to issues, implement changes, coordinate as required"></CollapsibleItem>
        </ul>
      ),
    },
    {
      key: "3",
      label: <CollapsibleLabel label="Feasibility"></CollapsibleLabel>,
      children: (
        <ul className="list-outside list-disc ml-4 -mt-3">
          <CollapsibleItem text="Prepare bid package and construction contract"></CollapsibleItem>
          <CollapsibleItem text="Bid and award construction contract"></CollapsibleItem>
          <CollapsibleItem text="Manage contractor performance"></CollapsibleItem>
          <CollapsibleItem text="Site visits during construction"></CollapsibleItem>
          <CollapsibleItem text="Review and approval of construction draws"></CollapsibleItem>
          <CollapsibleItem text="Respond to issues, implement changes, coordinate as required"></CollapsibleItem>
        </ul>
      ),
    },
    {
      key: "4",
      label: <CollapsibleLabel label="Interiors Planning"></CollapsibleLabel>,
      children: (
        <ul className="list-outside list-disc ml-4 -mt-3">
          <CollapsibleItem text="Prepare bid package and construction contract"></CollapsibleItem>
          <CollapsibleItem text="Bid and award construction contract"></CollapsibleItem>
          <CollapsibleItem text="Manage contractor performance"></CollapsibleItem>
          <CollapsibleItem text="Site visits during construction"></CollapsibleItem>
          <CollapsibleItem text="Review and approval of construction draws"></CollapsibleItem>
          <CollapsibleItem text="Respond to issues, implement changes, coordinate as required"></CollapsibleItem>
        </ul>
      ),
    },
  ];

  return (
    <>
      <div className="w-full mx-auto" style={{ maxWidth: maxW }}>
        <h2 className="font-lab-antiqua text-color-primary text-3xl md:text-4xl lg:text-5xl lg:mb-5 px-4 ">
          {title}
        </h2>

        <Collapse
          defaultActiveKey={[""]}
          ghost
          size="large"
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <DownOutlined
              className={`${isActive ? "rotate-180" : ""}`}
              style={{
                color: "var(--color-primary)",
                fontSize: "1rem",
              }}
            />
          )}
          items={list}
        />
      </div>
    </>
  );
};

export default CollapsiblePanel;