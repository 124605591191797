const InSvg = () => {
  return (
    <svg
      t="1725606828522"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="7598"
      width="32"
      height="32"
      fill="currentColor"
    >
      <path
        d="M224 149.28a75.2 75.2 0 0 0-79.04 80A75.04 75.04 0 0 0 224 307.36a76.96 76.96 0 0 0 80-78.4 76.96 76.96 0 0 0-80-79.68zM878.72 874.72V575.04c0-148.8-74.08-216.96-172.96-216.96a176 176 0 0 0-149.28 80h-3.04l-6.88-69.12H416c1.92 44.48 4 98.08 4 161.12v344.64h150.24V583.36A112 112 0 0 1 576 544a80 80 0 0 1 76-58.88c54.4 0 76.16 44.48 76.16 109.6v280zM149.28 369.44h150.24v505.28H149.28z"
        p-id="7599"
      ></path>
    </svg>
  );
};
export default InSvg;
