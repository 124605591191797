import { useState } from "react";

import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import CardImg from "@/components/card/card-img/CardImg";
import SearchHead from "@/components/search-head/SearchHead";

import Img01 from "@/assets/img/image01.jpg";
import ImgB from "@/assets/img/b.jpg";

import { Pagination } from "antd";

const ProjectsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const onChangePage = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  return (
    <>
      <SEO title="Projects"></SEO>
      <Layout>
        <Banner
          title="Projects"
          bgColor="#F2EDE8"
          description="DDDI Group utilises data and technology to deliver
          precise investment insights, empowering investors to
          achieve lasting wealth growth."
          textColor="#0A1E41"
          img={ImgB}
        ></Banner>

        <div className="mt-16 mb-6">
          <SearchHead></SearchHead>
        </div>

        <div className="page-content">
          <ul className="flex flex-wrap">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
              <li className="lg:w-1/3 mb-8 lg:mb-0" key={index}>
                <div className="lg:pr-14 lg:pb-14">
                  <CardImg
                    img={Img01}
                    title={"Core Temnitzpark BF33"}
                    bigTitle={true}
                    link={"/projects/core-temnitzpark-bf33"}
                    linkText="Learn More"
                  ></CardImg>
                </div>
              </li>
            ))}
          </ul>

          <Pagination
            className={`Pagination`}
            align="center"
            current={currentPage}
            onChange={onChangePage}
            defaultCurrent={1}
            total={50}
          />
        </div>
      </Layout>
    </>
  );
};

export default ProjectsPage;
