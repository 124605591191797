import styles from "./BannerPro.module.scss";

const BannerPro = (props) => {
  const { subTitle, title, description, img, bgColor, textColor } = props;

  const keyTakeaways = [
    {
      id: 1,
      title: "Location",
      content: <div>Phoenix, Arizona</div>,
    },
    {
      id: 2,
      title: "Product Type",
      content: <div>Residential</div>,
    },
    {
      id: 3,
      title: "Timeline",
      content: (
        <div>
          Project Start: July 2019
          <br />
          Project Completion: December 2021
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        className={`${styles["banner-pro"]}`}
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      >
        <div className="w-full flex justify-between items-start lg:flex-row flex-col mx-auto lg:pr-14">
          <div className="font-lab-antiqua px-8 lg:pl-14 md:w-auto lg:mt-28 lg:flex-1 lg:py-10 md:py-8 py-4 pt-8 lg:pr-0">
            <span className="text-xs mb-3">{subTitle}</span>
            <p className=" text-6xl lg:text-7xl 2xl:text-8xl w-full md:w-auto">
              {title}
            </p>
            <p className="mt-5 lg:w-4/6 lg:text-lg">{description}</p>
          </div>

          <div className="lg:w-1/2 md:h-full lg:min-h-80 md:min-h-64 relative py-5 lg:flex-1 w-full">
            <img
              src={img}
              alt=""
              className="w-full lg:h-auto md:h-64 h-48 object-cover aspect-video pl-7 lg:pl-0 md:absolute inset-0 lg:relative lg:top-14 md:top-2"
            />
          </div>
        </div>
      </div>

      <div className={`py-3 ${styles["key-takeaways"]}`}>
        <div className={`lg:p-14 md:p-8 p-4 mx-auto flex md:flex-row flex-col`}>
          {keyTakeaways.map((item, index) => (
            <div
              className="flex-1 flex flex-col justify-between md:border-l md:border-0 border-t border-gray-300 md:pl-4 p-4"
              key={index}
            >
              <div>
                <p className="font-bold text-sm">
                  {index + 1 < 10 ? "0" + (index + 1) : index + 1}
                </p>
                <h3 className="font-lab-antiqua lg:text-4xl md:text-3xl sm:text-2xl text-xl mt-2 lg:mb-10 md:mb-8 mb-6">
                  {item.title}
                </h3>
              </div>
              <div className="lg:text-lg md:text-base text-sm">
                {item.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default BannerPro;
