
import React from "react";
import "./GroupMembers.css"; // Assuming the CSS is in this file

const GroupMember = ({ href, imgSrc, altText }) => {
  return (
    <div className="member">
      <a href={href} target="_blank" rel="noopener noreferrer">
        <img src={imgSrc} alt={altText} />
      </a>
    </div>
  );
};


const GroupMembers = () => {
  const members = [
    { href: "https://www.cyberate.com.au/", imgSrc: "./images/brand/cyberate.jpg", altText: "Cyberate Technologies Logo" },
    { href: "https://www.cyberate.finance/", imgSrc: "./images/brand/Finance.jpg", altText: "Cyberate Investments Logo" },
    { href: "https://www.cyberate.investments/", imgSrc: "./images/brand/Investment.jpg", altText: "Cyberate Finance Logo" },
    { href: "https://www.cyberate.com.au/", imgSrc: "./images/brand/Project management.jpg", altText: "Cyberate Project Management Logo" },
    { href: "https://www.cyberidge.com.au/", imgSrc: "./images/brand/Cyberidge.jpg", altText: "Cyberidge Logo" },
    { href: "https://quantdigital.au/", imgSrc: "./images/brand/Quant Digital.jpg", altText: "Quant Digital Logo" }
  ];

  return (
    <div className="grid">
      {members.map(member => (
        <GroupMember key={member.href} href={member.href} imgSrc={member.imgSrc} altText={member.altText} />
      ))}
    </div>
  );
};

export default GroupMembers;