import Img01 from "@/assets/img/image01.jpg";
import CardImg from "@/components/card/card-img/CardImg";

const NewsModule = (prop) => {
  const { headText, bigItemTitle, rightLink, rightLinkText } = prop;

  const newsList = [
    {
      img: Img01,
      title:
        "TCC Breaks Ground on 1.35M-SF Industrial Park in Southwest Houston",
      content: "August 26, 2024",
      link: "/newsroom/tcc-breaks-ground-on-1-35m-sf-industrial-park-in-southwest-houston",
    },
    {
      img: Img01,
      title:
        "TCC Breaks Ground On New Operational Training Center for Puget Sound Energy",
      content: "August 15, 2024",
      link: "/newsroom/tcc-breaks-ground-on-new-operational-training-center-for-puget-sound-energy",
    },
    {
      img: Img01,
      title:
        "High Street Residential Sells Matson Mill Multifamily Community Outside Philadelphia",
      content: "August 9, 2024",
      link: "/newsroom/high-street-residential-sells-matson-mill-multifamily-community-outside-philadelphia",
    },
  ];

  return (
    <>
      <div className="relative lg:pb-6 pb-16">
        <div className="flex justify-between items-center lg:mb-12 mb-8">
          {headText && (
            <h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950">
              {headText}
            </h2>
          )}
        </div>

        <ul className="flex lg:gap-y-10 gap-y-7 flex-wrap pb-8">
          {newsList.map((item, index) => (
            <li key={index} className="lg:w-1/3 w-full">
              <div className="lg:pr-16">
                <CardImg
                  img={item.img}
                  subTitle={"Press Release"}
                  title={item.title}
                  bigTitle={bigItemTitle}
                  content={item.content}
                  link={item.link}
                  linkText="Learn More"
                />
              </div>
            </li>
          ))}
        </ul>

        {rightLinkText && (
          <div className="absolute bottom-0 right-0 mb-4 mr-4">
            <a
              href={rightLink}
              className="more-btn btn-hover bg-sky-950 text-white scale-110"
            >
              {rightLinkText}
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default NewsModule;
