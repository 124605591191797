const CornerMarkItem = (props) => {
  const { index, title1, title2, content, link } = props;

  return (
    <li
      key={index}
      className="lg:w-1/3 w-full relative before:border-t-2 before:border-l-2 before:border-sky-400 before:size-10 before:absolute before:left-0 before:top-0"
    >
      <div className="lg:mr-10 lg:mb-10 mb-10 lg:block md:flex md:justify-start py-4 px-5">
        <div className="md:flex md:w-1/2 lg:w-full">
          <span className="text-sm">{index}</span>
          <h3 className="text-rose-500 font-lab-antiqua text-6xl lg:text-8xl mb-2 lg:mb-4 mt-4 lg:mt-6">
            {title1}
          </h3>
        </div>

        {/* 这里对齐 title2, content 和 Learn More */}
        <div className={`md:w-2/5 lg:w-full`}>
          <h3 className="font-lab-antiqua underline text-6xl lg:text-6xl mb-2 lg:mb-4 mt-4 lg:mt-6">
            {title2}
          </h3>
          <p className="text-lg font-bold mb-4">{content}</p>
          <div></div>
          {/* <a href={link} className="more-btn btn-hover text-xs">
            Learn More
          </a> */}
        </div>
      </div>
    </li>
  );
};

export default CornerMarkItem;
