import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import SearchHead from "@/components/search-head/SearchHead";
import SearchResultsItem from "@/components/search-results-item/SearchResultsItem";

import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Pagination } from "antd";

import Img01 from "@/assets/img/image01.jpg";

const SearchResultsPage = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const q = urlParams.get("q");

  const [searchText, setSearchText] = useState(q || "");
  const onSearch = () => {
    navigate("/search-results?q=" + searchText);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const onChangePage = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  return (
    <>
      <SEO title="search-results"></SEO>
      <Layout>
        <div className="py-10 sm:py-16 md:py-20 lg:py-32 lg:px-14 md:px-8 px-6 bg-[#b1b8c4] text-slate-800">
          <h1 className="xl:text-8xl xl:max-w-24 lg:text-7xl  md:text-6xl text-5xl font-black leading-tight font-lab-antiqua">
            Search Results
          </h1>

          <div className={`mt-20 border-b-2 border-slate-900 max-w-6xl`}>
            <Input
              variant="borderless"
              placeholder="Search..."
              className={`mb-1 search-input`}
              value={searchText}
              onInput={(event) => {
                setSearchText(event.target.value);
              }}
              onPressEnter={onSearch}
              addonAfter={
                <SearchOutlined
                  className="text-2xl cursor-pointer hover:text-sky-400"
                  onClick={onSearch}
                />
              }
              suffix={
                <CloseOutlined
                  className={`text-2xl cursor-pointe text-gray-500 hover:text-sky-400 ${
                    searchText !== "" ? "w-auto" : "w-0"
                  }`}
                  onClick={() => setSearchText("")}
                />
              }
            />
          </div>
        </div>

        <div className="lg:px-14 lg:py-8 md:px-8 px-6 py-4 ">
          <div className="my-0 lg:-mx-8 ">
            <SearchHead></SearchHead>
          </div>
        </div>

        <div className="page-content">
          <ul className="">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
              <li className="my-8" key={index}>
                <SearchResultsItem
                  img={Img01}
                  subTitle={"Berlin, Germany"}
                  title={
                    "Trammell Crow Company and CBRE IM Acquire 38,500 SQ M Logistics Site in Berlin"
                  }
                  description={"September 11, 2024"}
                  link={"/newsroom/trammell-crow-company"}
                  phone='123-456-7890'
                  mobile='123-456-7890'
                  email='123-456-7890'
                ></SearchResultsItem>
              </li>
            ))}
          </ul>

          <Pagination
            className={`Pagination`}
            align="center"
            current={currentPage}
            onChange={onChangePage}
            defaultCurrent={1}
            total={50}
          />
        </div>
      </Layout>
    </>
  );
};

export default SearchResultsPage;
