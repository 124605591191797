import { useState } from "react";

import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import ContactUsItemTwo from "@/components/contact-us-list/ContactUsItemTwo";
import CollapsiblePanel from "@/components/collapsible-panel/CollapsiblePanel";

import { PhoneFilled, MobileFilled, MailFilled } from "@ant-design/icons";
import { Popover } from "antd";

import user01 from "@/assets/img/user01.png";

const PeoplePage = () => {
  const [iconIndexHover, setIconIndexHover] = useState(0);

  return (
    <>
      <SEO title="Atlanta"></SEO>
      <Layout>
        <div className="md:py-10 py-8">
          <div
            className="2xl:max-w-screen-2xl mx-auto flex flex-col md:flex-row md:justify-center md:items-end 
          lg:gap-x-20 md:gap-x-12 gap-y-5 px-8 lg:px-14 md:px-8 xl:max-w-screen-xl "
          >
            <div className="xl:size-96 lg:size-80 md:size-72 w-full">
              <img src={user01} alt="" className="w-full" />
            </div>

            <div className=" mb-10 flex-1">
              <div className="border-b">
                <h1 className=" xl:text-6xl lg:text-5xl text-4xl font-bold font-lab-antiqua text-color-primary">
                  Brandon Houston
                </h1>
                <h2 className="md:my-10 my-7 lg:text-lg md:text-base text-sm font-lab-antiqua text-color-secondary">
                  Georgia Market Leader
                </h2>
              </div>
              <div className="mt-3">
                <ul className="flex flex-wrap gap-y-4 gap-x-8">
                  <li
                    onMouseEnter={() => {
                      setIconIndexHover(1);
                    }}
                    onMouseLeave={() => {
                      setIconIndexHover(0);
                    }}
                  >
                    <Popover placement="top" title={"123-456-7890"}>
                      <a href="tel:123-456-7890">
                        <PhoneFilled
                          className={`mr-2`}
                          style={{
                            color:
                              iconIndexHover === 1 ? "var(--active-color)" : "",
                          }}
                        />
                        Phone
                      </a>
                    </Popover>
                  </li>
                  <li
                    onMouseEnter={() => {
                      setIconIndexHover(2);
                    }}
                    onMouseLeave={() => {
                      setIconIndexHover(0);
                    }}
                  >
                    <Popover placement="top" title={"123-456-7890"}>
                      <a href="tel:123-456-7890">
                        <MobileFilled
                          className={`mr-2`}
                          style={{
                            color:
                              iconIndexHover === 2 ? "var(--active-color)" : "",
                          }}
                        />
                        Mobile
                      </a>
                    </Popover>
                  </li>
                  <li
                    onMouseEnter={() => {
                      setIconIndexHover(3);
                    }}
                    onMouseLeave={() => {
                      setIconIndexHover(0);
                    }}
                  >
                    <a href="mailto:123-456-7890">
                      <MailFilled
                        className={`mr-2`}
                        style={{
                          color:
                            iconIndexHover === 3 ? "var(--active-color)" : "",
                        }}
                      />
                      Email
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="page-content">
          <h2 className="text-color-primary font-lab-antiqua text-3xl md:text-4xl lg:text-5xl mb-7 mx-auto content-text-max-w">
            Professional Experience
          </h2>

          <div className="mx-auto leading-8 md:leading-9 md:text-lg text-base content-text-max-w flex md:block flex-col">
            <div className="md:float-right md:ml-16 order-4">
              <div className="w-72 py-10 px-4 bg-[--color-secondary-light]">
                <p className="text-xs mb-2 uppercase">Media Contact</p>
                <ContactUsItemTwo></ContactUsItemTwo>
              </div>
            </div>
            <div className="text-lg leading-8">
              <p className="mb-10">
                Brandon Houston is the Market Leader and Managing Director for
                TCC’s Atlanta office, which develops throughout Georgia. He is
                responsible for the day-to-day operations of the office and the
                strategic oversight of all new development and investment
                activity throughout the region. His area of expertise includes
                deal structuring, market analysis, negotiation and cultivating
                client and partner relationships.
              </p>
              <p className="mb-10">
                Before assuming leadership of the Georgia Market in 2022,
                Brandon was responsible for sourcing, shaping and executing new
                office and life science development and investment
                opportunities. Since 2005, Brandon has developed over 3 million
                square feet of Class AA office valued at over $1.5 billion.
                Prior to relocating to Atlanta in 2017, Brandon served as a
                Principal the Houston office of TCC.
              </p>
            </div>
          </div>

          <div className="mt-32">
            <CollapsiblePanel title="" maxW="768px"></CollapsiblePanel>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PeoplePage;
