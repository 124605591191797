import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import JoinTheCommunity from "@/components/join-the-community/JoinTheCommunity";
import { useTranslation } from "react-i18next";
import Img01 from "@/assets/img/image01.jpg";
import BrandStory from "@/components/about-us/BrandStory";
import Team from "@/components/about-us/Team";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation

const AboutUsPage = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <SEO title="About Us" />
      <Layout>
        <Banner
          title="About Us"
          bgColor="#F2EDE8"
          description="For property investors and homeowners seeking comprehensive and innovative
          services, DDDI Group is a leading property development and financial solutions provider
          that offers the most integrated, end-to-end property development services. This is
          achieved through its commitment to leveraging big data across project management,
          lending financial services, property development, and construction."
          textColor="#0A1E41"
          img={Img01}
        />


        {/* Brand Story Details Section */}
        <div className="flex gap-x-10 md:flex-row flex-col page-content pt-10 pb-16">
          <div className="flex flex-col lg:flex-row md:flex-1 lg:gap-x-20">
            <div className="flex flex-col">
              <p className="text-lg text-gray-700 mb-8">
                DDDI Group is a data-driven leader in property development services. DDDI Group
                believes that in a rapidly changing market environment, only data can provide accurate
                and reliable guidance. With a passion for technology and a strong belief in data, DDDI
                Group utilises its unique big data analysis techniques to create personalised investment
                plans for investors, breaking through traditional limitations.
              </p>
              <p className="text-lg text-gray-700 mb-8">
                Behind every successful property development case lies thorough data analysis and
                verification. DDDI Group has established a solid foundation in the field of real estate
                investment and development. Whether for young first-time homebuyers or experienced
                investors looking to expand their portfolios, DDDI Group provides them with scientific,
                data-driven decision-making support.
              </p>
              <p className="text-lg text-gray-700 mb-8">
                DDDI Group is not just a property development services provider but also a long-term
                partner for its investors. Our business covers property investment, construction, property
                development, and lending financial services. The group represents various market
                segments through its sub-brands, including Cyberate Technologies, Cyberate
                Investments, Cyberate Finance, Cyberate Project Management, Cyberidge
                Construction, and Quant Digital. By extending our services across the entire industry
                chain, DDDI Group effectively integrates resources to provide efficient and precise one-
                stop solutions for property investment and development.
              </p>
              <p className="text-lg text-gray-700 mb-8">
                By continuously optimising data analysis models and IT technologies, DDDI Group
                remains at the forefront of innovation, providing investors with the most forward-looking
                investment advice. We believe that data is more than just numbers; it is the key to
                driving decision-making and success.
              </p>
              <p className="text-lg text-gray-700 mb-8">
                The group is committed to operating responsibly and contributing to a greener future
                for Australia. DDDI is actively driving a sustainable future for the Australian real estate
                industry, focusing on environmental protection and sustainability in its business
                operations, and striving to reduce its impact on the environment to achieve a greener
                society.
              </p>
              <p className="text-lg text-gray-700 mb-8">
                Looking ahead, DDDI Group will continue to uphold the philosophy of "data-driven
                decision- making,
                " constantly innovating to create more value for investors. Our goal is to
                become a global leader in real estate investment and development, unlocking the door
                to success for investors through data and technology. DDDI Group is dedicated to
                empowering real estate investment and development with data and technology,
                providing investors with the most scientific and reliable decision-making support to help
                them achieve wealth growth.
              </p>
              <h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950 mb-6 mt-12">
                Our mission
              </h2>
              <p className="text-lg text-gray-700 mb-8">
                We empower property development solutions with data and technology, providing
                investors with the most precise and reliable investment insights, enabling them to achieve
                sustained wealth growth.
              </p>
              <h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950 mb-6 mt-12">
                Our Vision
              </h2 >
              <p className="text-lg text-gray-700 mb-8">
                Our vision is to be the world’s leading property development and investment service
                group, driving client success with strategic data and technology solutions.
              </p>
              <h2 className="font-lab-antiqua lg:text-6xl text-5xl font-medium text-blue-950 mb-6 mt-12">
                Core Values
              </h2>
              <ul className="list-disc pl-4 text-lg text-gray-700 mb-8">
                <li>Data-Centric</li>
                <li>Innovative Insight</li>
                <li>Holistic Solutions</li>
              </ul>

            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AboutUsPage;
