const MainlSvg = () => {
  return (
    <svg
      t="1726127893311"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="8979"
      width="32"
      height="32"
      fill="currentColor"
    >
      <path
        d="M981 359v419c0 43.078-34.922 78-78 78H120c-43.078 0-78-34.922-78-78V359h3.5l405.513 312.066c35.951 27.667 86.023 27.667 121.974 0L978.5 359H981z"
        p-id="8980"
      ></path>
      <path
        d="M572.971 612.1l375.614-288.934c37.646-28.96 44.69-82.954 15.73-120.601A86 86 0 0 0 896.15 169h-768.3c-47.496 0-86 38.504-86 86a86 86 0 0 0 33.565 68.166L451.03 612.099c35.945 27.65 85.997 27.65 121.942 0z"
        p-id="8981"
      ></path>
    </svg>
  );
};


export default MainlSvg;