import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";

import { Collapse, Form, Input, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import Img01 from "@/assets/img/image01.jpg";

const CollapsibleLabel = ({ label }) => {
  return (
    <p
      className="text-color-primary font-medium lg:text-xl text-lg hover:underline underline-offset-2 decoration-2 border-t-2 border-color-primary pt-6 "
      style={{ width: "calc(100% + 2.5rem)" }}
    >
      {label}
    </p>
  );
};

const Careers = () => {
  const handleScrollToForm = () => {
    const formElement = document.getElementById("contact-form");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <SEO title="Offices"></SEO>
      <Layout>
        <Banner
          title="Careers"
          bgColor="#F2EDE8"
          textColor="#0A1E41"
          img={Img01}
        ></Banner>

        <div className="page-content">
          <h2 className="text-color-primary font-lab-antiqua text-3xl md:text-4xl lg:text-5xl mb-7">
            Embark on your career journey and join us
          </h2>

          {/* Button Container */}
          {/* <div className="flex justify-end my-8">
            <Button
              type="primary"
              onClick={handleScrollToForm}
              style={{
                backgroundColor: "red",
                color: "white",
                border: "none",
                padding: "20px 30px",
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ flex: 1, textAlign: "left" }}>CLICK HERE</span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                &gt;&gt;
              </span>
            </Button>
          </div> */}

          <div className={`my-32`}>
            <Collapse
              defaultActiveKey={[""]}
              ghost
              size="large"
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <DownOutlined
                  className={`mt-16 ${isActive ? "rotate-180" : ""}`}
                  style={{
                    color: "var(--color-primary)",
                    fontSize: "1rem",
                  }}
                />
              )}
              // items={collapseList}
            />
          </div>

          {/* Contact Form */}
          <div id="contact-form" className="my-16">
            <h3 className="text-color-primary font-medium text-2xl mb-4">
              Contact Us
            </h3>
            <Form layout="vertical">
              <Form.Item
                label="Your Name"
                name="name"
                rules={[{ required: true, message: "Please enter your name!" }]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number!",
                  },
                ]}
              >
                <Input placeholder="Enter your phone number" />
              </Form.Item>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[{ required: true, message: "Please enter a subject!" }]}
              >
                <Input placeholder="Enter subject" />
              </Form.Item>
              <Form.Item
                label="Your Message"
                name="message"
                rules={[
                  { required: true, message: "Please enter your message!" },
                ]}
              >
                <Input.TextArea rows={4} placeholder="Enter your message" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: "red" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Careers;
