import { Link } from "react-router-dom";  // 导入 Link 组件
import styles from "./NavBar.module.scss";
import { RightOutlined } from "@ant-design/icons";

const NavDrawer = (props) => {
  const { title, description, btnText, btnLink, linkGroup } = props;

  return (
    <>
      <div className={`${styles["nav-drawer"]}`}>
        <div className={`${styles["nav-drawer-content"]}`}>
          <div className={`${styles["nav-drawer-content-grid"]}`}>
            <div className={`${styles["nav-drawer-content-left"]}`}>
              <p
                className={`${styles["nav-drawer-content-left_title"]} mb-5 lg:text-5xl md:text-3xl text-2xl`}
              >
                {title}
              </p>

              <p className="mb-10 text-gray-600 lg:text-xl md:text-xl">
                {description}
              </p>

              <a
                href={btnLink}
                className={`text-white bg-gray-700 py-3 px-5 rounded inline-block ${styles["a-btn"]} btn-hover`}
              >
                {btnText}
              </a>
            </div>

            <div className={`${styles["nav-drawer-content-right"]}`}>
              {linkGroup
                .sort((a, b) => a.sort - b.sort)
                .map((group, index) => (
                  <div
                    className={styles["nav-drawer-content-right_group"]}
                    key={index}
                  >
                    <p className={styles["nav-drawer-content-right_title"]}>
                      {group.title}
                    </p>
                    <ul className={styles["nav-drawer-content-right_link-ul"]}>
                      {group.list
                        .sort((a, b) => a.sort - b.sort)
                        .map((link, i) => (
                          <li key={i}>
                            {/* <a href={link.url} className={styles["nav-link"]}>
                              {link.name}
                            </a> */}
                            {/* 判断是否是特定按钮，需要组件跳转 */}
                            {link.name === "Contact Us" ? (
                              <Link to="/get-in-touch-form" className={styles["nav-link"]}>
                                {link.name}
                              </Link>
                            ) : (
                              <a href={link.url} className={styles["nav-link"]}>
                                {link.name}
                              </a>
                            )}
                            <RightOutlined
                              style={{
                                color: "#0a1e41",
                                fontSize: "0.7rem",
                              }}
                            />
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavDrawer;
