import { PhoneFilled, MobileFilled, MailFilled } from "@ant-design/icons";
import { Popover } from "antd";

import user01 from "@/assets/img/user01.png";
import styles from "./ContactUsList.module.scss";

const ContactUsItem = () => {
  return (
    <>
      <div className={`grid md:pt-12 lg:pt-0 ${styles["contact-card"]} `}>
        <div
          className={`font-lab-antiqua my-3 md:my-0 ${styles["contact-card-title"]}`}
        >
          <h3 className="xl:text-3xl lg:text-2xl md:text-3xl text-2xl text-gray-800 mb-2">
            <a href="/people/matthew-hill" className="hover:underline">
              Matthew Hill
            </a>
          </h3>
          <p className="text-xs">Principal, Capital Markets - Debt</p>
        </div>

        <a
          href="/people/matthew-hill"
          className={`w-full lg:mb-3 relative ${styles["contact-card-image"]}`}
        >
          <img
            src={user01}
            alt=""
            className="object-cover w-full block aspect-square"
          ></img>
        </a>

        <div
          className={`${styles["contact-card-description"]} ${styles["contact-card-description-md"]} `}
        >
          <div>
            <ul className="flex flex-wrap gap-4">
              <li>
                <Popover placement="top" content={"123-456-7890"}>
                  <a
                    href="tel:123-456-7890"
                    className={`hover:text-[--active-color]`}
                  >
                    <PhoneFilled className="mr-2 transition-colors duration-200 ease-in-out" />
                    <span className="text-slate-800">Phone</span>
                  </a>
                </Popover>
              </li>
              <li>
                <Popover placement="top" content={"123-456-7890"}>
                  <a
                    href="tel:123-456-7890"
                    className={`hover:text-[--active-color]`}
                  >
                    <MobileFilled className="mr-2 transition-colors duration-200 ease-in-out" />
                    <span className="text-slate-800">Mobile</span>
                  </a>
                </Popover>
              </li>
              <li>
                <a
                  href="mailto:123-456-7890"
                  className={`hover:text-[--active-color]`}
                >
                  <MailFilled className="mr-2 transition-colors duration-200 ease-in-out" />
                  <span className="text-slate-800">Email</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="font-lab-antiqua text-sm mt-5">
            <a
              href="/people/matthew-hill"
              className="hover:underline decoration-2"
            >
              View Profile
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsItem;
