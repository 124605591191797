const FacebookSvg = () => {
  return (
    <svg
      t="1726128176439"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="9972"
      width="32"
      height="32"
      fill="currentColor"
    >
      <path
        d="M758.88 43.424C743.424 41.152 690.304 36.576 628.576 36.576 499.424 36.576 410.88 115.424 410.88 260L410.88 384.576 265.152 384.576 265.152 553.728 410.88 553.728 410.88 987.424 585.728 987.424 585.728 553.728 730.88 553.728 753.152 384.576 585.728 384.576 585.728 276.576C585.728 228 598.88 194.304 669.152 194.304L758.88 194.304 758.88 43.424Z"
        p-id="9973"
      ></path>
    </svg>
  );
};
export default FacebookSvg;
