import ContactUsItem from "./ContactUsItem";

const ContactUsList = ({ headText }) => {
  const USList = [1, 2, 3, 4, 5];

  return (
    <>
      {headText && (
        <h2 className="lg:text-6xl md:text-5xl text-4xl font-lab-antiqua font-bold text-sky-950 mb-10">
          {headText}
        </h2>
      )}

      <ul className="flex flex-col lg:flex-row flex-wrap">
        {USList.map((item, index) => (
          <li key={index} className="lg:w-1/3">
            <div className="lg:mr-16 lg:mb-16">
              <ContactUsItem></ContactUsItem>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ContactUsList;
