// import { useState } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Select } from "antd";

import styles from "./GetInTouchForm.module.scss";

const { TextArea } = Input;

const GetInTouchForm = (props) => {
  const handleClose = () => {
    // props.onClose();

    //判断如果有props就正常关闭，没有props，也就是主导航栏直接跳转，则返回上一层
    if (props.onClose) {
      props.onClose();
    } else {
      // 如果没有传递 onClose 函数，跳转回前一个页面或者根页面
      window.history.back(); 
    }
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className={`mx-auto w-full p-8 lg:p-6 ${styles["GetInTouchForm"]}`}>
        <div
          className={`text-right lg:my-5 ${styles["GetInTouchForm-header"]}`}
        >
          <CloseOutlined
            className="cursor-pointer"
            onClick={handleClose}
            style={{
              fontSize: "1.4rem",
            }}
          />
        </div>

        <div
          className={`flex lg:flex-row flex-col lg:px-14 md:px-8 py-12 ${styles["GetInTouchForm-body"]}`}
          style={{
            backgroundColor: "var(--color-secondary-light-2)",
          }}
        >
          <div className="lg:w-5/12 lg:pr-14 lg:mr-10 lg:mb-0 mb-16">
            <h2 className="font-lab-antiqua md:text-7xl text-5xl">
              Learn more about our company, our team will response you as soon
              as possible.
            </h2>
          </div>
          <div className="flex-1">
            <Form
              name="formName"
              layout="vertical"
              size="large"
              initialValues={{
                isAgree: false,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className={`${styles["GetInTouchForm-form-row"]}`}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  className="flex-1 border-b-2 border-gray-300"
                >
                  <Input
                    placeholder="First Name"
                    variant="borderless"
                    className="lg:text-xl"
                  />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  className="flex-1 border-b-2 border-gray-300"
                >
                  <Input
                    placeholder="Last Name"
                    variant="borderless"
                    className="lg:text-xl"
                  />
                </Form.Item>
              </div>

              <div className={`${styles["GetInTouchForm-form-row"]}`}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email!",
                    },
                  ]}
                  className="flex-1 border-b-2 border-gray-300"
                >
                  <Input
                    placeholder="yourname@gmail.com"
                    variant="borderless"
                    className="lg:text-xl"
                  />
                </Form.Item>
                <Form.Item
                  label="Company"
                  name="company"
                  className="flex-1 border-b-2 border-gray-300"
                >
                  <Input
                    placeholder="Enter Your Company Name"
                    variant="borderless"
                    className="lg:text-xl"
                  />
                </Form.Item>
              </div>

              <Form.Item
                label="Job Title"
                name="jobTitle"
                className="flex-1 border-b-2 border-gray-300"
              >
                <Select
                  placeholder="Job Title"
                  variant="borderless"
                  style={{ flex: 1 }}
                  options={[
                    { value: "Select", label: "Select" },
                    { value: "C-Suite", label: "C-Suite" },
                    { value: "Vice President", label: "Vice President" },
                    { value: "Director", label: "Director" },
                  ]}
                />
              </Form.Item>

              <Form.Item label="Comments" name="comments">
                <TextArea rows={6} />
              </Form.Item>

              <div
                className={`${styles["GetInTouchForm-form-row"]} justify-between`}
              >
                <Form.Item name="isAgree" valuePropName="checked">
                  <Checkbox
                    className={`GetInTouchForm-form-row-checkbox lg:text-base text-sm`}
                  >
                    Opt in to receive future communications from DDDI Privacy
                    Policy *
                  </Checkbox>
                </Form.Item>

                <Form.Item className="text-right">
                  <button className="more-btn btn-hover bg-sky-950 text-white">
                    Submit
                  </button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetInTouchForm;
