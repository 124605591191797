import { useState } from "react";
import { Drawer } from "antd";
import GetInTouchForm from "@/components/get-in-touch/GetInTouchForm";
import { LoadScript, GoogleMap, Marker } from "@react-google-maps/api";

const ContactUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Google Maps configuration
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: -34.905439,// Replace with your latitude
    lng: 138.6099002,  // Replace with your longitude
  };

  const mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d687.6981823573348!2d138.60441464956787!3d-34.92562454549074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cf5b3aa80087%3A0x3195c74e901eddb6!2s147%20Pirie%20St%2C%20Adelaide%20SA%205000!5e0!3m2!1szh-CN!2sau!4v1729924425045!5m2!1szh-CN!2sau";

  return (
    <>
      <div className="bg-[#faf7f5] lg:pt-10 pt-6 lg:pb-16 pb-8">
        <div className="page-content flex lg:flex-row flex-col justify-between">
          <div className="lg:pr-5 lg:w-5/12 border-neutral-300 lg:border-r-2 lg:border-b-0 lg:pb-0 pb-10 border-b-2">
            <h2 className="lg:text-6xl md:text-5xl font-lab-antiqua text-sky-950 mb-5 md:mb-8">
              Contact Us
            </h2>
            <p className="pb-6 mb-6 text-sm text-gray-700">
              Thank you for your interest in DDDI Group. Please use this email
              form to ask questions, request information or send us feedback on
              our website.
            </p>

            <button
              className="more-btn btn-hover bg-sky-950 text-white"
              onClick={showModal}
            >
              Contact Us
            </button>
          </div>

          <div className="lg:w-3/5 lg:-mt-5 mt-6 lg:pl-10 pl-3">
            {/* Google Map Component */}

            <iframe src={mapSrc} allowFullScreen style={{ width: "100%", height: "600px", borderRadius: 2 }}></iframe>
            {/* <LoadScript googleMapsApiKey="AIzaSyClqiJpCr27Cxp3AOPwv6_B1kMZZP8ARAM">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={15}
              >
                <Marker
                  position={center}
                  label={{
                    text: "DDDI Group",
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                  icon={{
                    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png', // 使用 Google Map 自带的红色图标
                  }}
                />
              </GoogleMap>
            </LoadScript> */}

            {/* Contact Information Section */}
            <div className="flex flex-wrap lg:flex-nowrap lg:mt-5 mt-6 lg:gap-20 gap-6 text-sm text-gray-700">
              {/* Address */}
              <div className="flex-1">
                <strong>Address</strong>
                <br />
                3/22 Melbourne Street
                <br />
                North Adelaide, SA 5006
              </div>

              {/* Phone */}
              <div className="flex-1">
                <strong>Phone</strong>
                <br />
                (08) 8239 1119
              </div>

              {/* Media */}
              <div className="flex-1">
                <strong>Email</strong>
                <br />
                info@dddi.com.au
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title=""
        placement="bottom"
        height={"100vh"}
        width={"100vw"}
        onClose={handleClose}
        open={isModalOpen}
        closeIcon={false}
        styles={{
          header: {
            borderBottom: `0px`,
          },
          body: {
            padding: `0px`,
          },
        }}
      >
        <GetInTouchForm onClose={handleClose}></GetInTouchForm>
      </Drawer>
    </>
  );
};

export default ContactUs;
