import React, { useState, useEffect } from "react";

import { DownOutlined, CloseOutlined } from "@ant-design/icons";

import styles from "./SearchHeadDropdown.module.scss";

const SearchHeadDropdown = (props) => {
  const { label, optionsList, checkList, onChangeSearch } = props;

  const classNameItem = `SearchHeadDropdown-${Math.random()
    .toString(36)
    .substring(2)}`;

  const [isItemSelected, setIsItemSelected] = useState(false);

  const handleDocumentClick = (event) => {
    const targetDiv = document.querySelector("." + classNameItem);
    if (event.target.classList.contains("clear-div")) {
      return;
    }

    if (!targetDiv || (targetDiv && !targetDiv.contains(event.target))) {
      setIsItemSelected(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  });

  const [checkOptions, setCheckOptions] = useState([]);
  useEffect(() => {
    let list = checkList.filter((v) =>
      optionsList.some((v2) => v2.id === v.id && v2.label === v.label)
    );
    setCheckOptions(list);
  }, [checkList, optionsList]);

  const changeItem = (item) => {
    let newList = [];
    if (checkOptions && checkOptions.length > 0) {
      if (checkOptions.some((v) => v.id === item.id)) {
        newList = checkOptions.filter((v) => v.id !== item.id);
      } else {
        newList = [...checkOptions, item];
      }
    } else {
      newList.push(item);
    }

    onChangeSearch(newList);
  };

  return (
    <>
      <div
        className={`${styles["SearchHeadDropdown"]} ${classNameItem} ${
          isItemSelected ? styles["SearchHeadDropdown_active"] : ""
        } `}
      >
        <div
          className="cursor-pointer text-sm hover:bg-slate-100 lg:px-4 py-4 transition-colors duration-300 ease-in-out flex justify-between"
          onClick={() => setIsItemSelected(!isItemSelected)}
        >
          <div
            className={` ${
              isItemSelected
                ? "underline underline-offset-4 decoration-sky-400 decoration-2 lg:no-underline"
                : ""
            }`}
          >
            <span>{label}</span>
            {checkOptions.length > 0 && (
              <span className="ml-1">({checkOptions.length})</span>
            )}
          </div>
          <DownOutlined
            className={`transition-all duration-300 ease-in-out ml-4 ${
              isItemSelected ? "rotate-180" : ""
            }`}
          />
        </div>

        <ul className="lg:w-80 bg-slate-100">
          {checkOptions.length > 0 && (
            <div
              className={`bg-slate-100 cursor-pointer sticky left-3 right-3 z-20 top-0 pt-3 hidden lg:block`}
              onClick={() => onChangeSearch([])}
            >
              <div className="bg-slate-300 text-xs p-3 flex items-center justify-between">
                Clear
                <CloseOutlined />
              </div>
            </div>
          )}

          {(optionsList || []).map((item, index) => (
            <li
              key={index}
              onClick={() => changeItem(item)}
              className={`flex items-center justify-between py-5 px-2 mx-2 text-slate-800 cursor-pointer border-t border-slate-300 ${
                checkList.some(
                  (v) => v.id === item.id && v.label === item.label
                )
                  ? styles["check-item"]
                  : ""
              } 
            `}
            >
              <label className="flex items-center text-xs">
                <p className="">{item.label}</p>
                <span className="ml-2">({item.count})</span>
              </label>

              <div
                className={`size-4 border border-black ${styles["check-icon"]} ${styles["check-icon-light"]}`}
              ></div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SearchHeadDropdown;
